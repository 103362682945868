<template>
    <v-container fluid class="pa-0">
        <!-- <slot name="submenu" /> -->

        <HubPageRenderer
			v-if="displayPage"
			:page="mPage"
			@copy-page="copyPage"
			@edit-page="editPage"
		/>

		<div v-else class="site-width pa-4">
			<v-progress-linear v-if="loading" indeterminate color="#46C3B2" />
			<v-alert v-else text color="#46C3B2"><h5 class="mb-0">Page not found</h5></v-alert>
		</div>

		<HubPageEditorDialog
			v-model="doShowEditorDialog"
			:page="mPage"
			:mode="hubPagesEditorMode"
			@saved="onHubPageSave"
		/>
    </v-container>
</template>

<script>
import HubPageRenderer from '../components/HubPageRenderer.vue'
import HubPageEditorDialog from '../components/HubPageEditorDialog.vue'

import { getHubPageBySlug } from '../api/index'
import { displayItemByStatus, EDITOR_MODE } from '../config/index'

export default {
    components: {
		HubPageRenderer,
		HubPageEditorDialog,
	},
	props: {
		page: { type: Object },
		slug: { type: String },
	},
    data () {
        return {
			loading: false,
			loaded: false,
			mPage: null,

			doShowEditorDialog: false,
			hubPagesEditorMode: EDITOR_MODE.edit,
        }
    },
	mounted () {
		if (this.page) {
			this.mPage = this.page
		} else if (this.slug) {
            this.loadPage(this.slug)
        } else if (this.$route.params.slug) {
            this.loadPage(this.$route.params.slug)
        }
	},
    computed: {
		displayPage () {
			if (!this.mPage) { return false }
			if (this.hasManageContentPermission) { return true }
			return displayItemByStatus(this.mPage)
		},
		hasManageContentPermission () {
			return this.hasPermission(['manage:Content'])
		},
    },
	watch: {
		'$route.params.slug' (newV) {
            if (newV) {
                this.loadPage(newV)
            }
        },
		slug () {
			this.loadPage(this.slug)
        },
		page () {
			this.mPage = this.page
		},
		doShowEditorDialog (v) {
			if (!v) {
				this.hubPagesEditorMode = EDITOR_MODE.edit
			}
		},
	},
	methods: {
		async loadPage (slug) {
			this.mPage = null
            this.loading = true
            const res = await getHubPageBySlug(slug)
			if (res.error) {
				if (res.message === 'Aborted') { return }
				console.log(res.error)
				this.showError('Whoops! There was a problem loading Hub Page.<br>', res.message)
			} else {
				this.mPage = res
				this.loading = false
			}
        },
		copyPage () {
			this.hubPagesEditorMode = EDITOR_MODE.copy
			this.doShowEditorDialog = true
		},
		editPage () {
			this.hubPagesEditorMode = EDITOR_MODE.edit
			this.doShowEditorDialog = true
		},
		onHubPageSave (savedPage) {
			this.doShowEditorDialog = false
			if (savedPage.slug !== this.mPage.slug && this.$route.name === 'HubPage') {
				// for copy mode and HubPage route
				this.$router.push({ params: { slug : savedPage.slug } })
			} else {
				this.loadPage(savedPage.slug)
			}
		},
	},
}
</script>
